var gftt = gftt || {};

(function($){
	'use strict';

	gftt.colorPickerChoicesDynamicPosition = function( form_id ) {
		var $formWrapper = ( typeof form_id !== "undefined" ) ? $('#gform_wrapper_'+form_id) : $(".gform_wrapper");
		$formWrapper.find('.color-picker-choice .gftt-icon').each(function(){
			var $icon = $(this);
			var $choice = $icon.parent();
			var $label = $choice.find('> label');
			var offset = ( $label.width() - $choice.width() ).toString();
			$icon.css('transform', 'translateX(' + offset + 'px)');
		});
	};

	gftt._initLabel = function( $label, forced ) {

		var $gfLabel = $label.closest('.gfield_label');
		if ( !$gfLabel.length ) {
			$gfLabel = $label.closest('.gform-field-label');
		}

		$gfLabel.on('click', function(e){
			var $target = $(e.target);
			if ( $target.hasClass('gftt-label') || $target.closest('.gftt-label').length ) {
				e.preventDefault();
				e.stopImmediatePropagation();
				var $container = $(this).closest('.gfield').find('.ginput_container');
				if ($container.length) {
					if ( $container.find('input:first').not(':radio').not(':checkbox').length ) {
						$container.find('input:first').not(':radio').not(':checkbox').focus();
					}
					else if ( $container.find('textarea:first').length ) {
						$container.find('textarea:first').focus();
					}
					else if ( $container.find('select:first').length ) {
						$container.find('select:first').focus();
					}
				}
				var $choice = $(this).closest('[class*="gchoice"]');
				if ( $choice.length ) {
					$choice.find('input').click();
				}
			}
		});

	};

	gftt._initTooltips = function( $el, forced ) {

		var tipID = $el.data('tid');
		var $tip = $('#'+tipID);
		var $otherTip = $tip.closest('form').find('.gftt-icon[data-placement]');
		var tipPlacement = $tip.data('placement');
		if ( !tipPlacement ) {
			if ( $otherTip.length ) {
				tipPlacement = $otherTip.data('placement');
			}
			else {
				tipPlacement = 'nw-alt';
			}
			$tip.data('placement', tipPlacement);
			$tip.attr('data-placement', tipPlacement);
		}
		if ( !$tip.attr('tabindex') && $otherTip.attr('tabindex') ) {
			$tip.attr('tabindex', $otherTip.attr('tabindex'));
		}
		$tip.data('powertiptarget', tipID+'-content');
		var userOptions = gform.applyFilters( 'gftt_tooltip_custom_options', {} );
		var mainOptions = {
			manual: false,
			followMouse: false,
			mouseOnToPopup: true,
			smartPlacement: true,
			popupClass: tipID,
			placement: tipPlacement
		};
		var options = $.extend( true, mainOptions, userOptions );
		$tip.powerTip(options);
		$tip.on({
			powerTipPreRender: function(){
				$('body').addClass('gftt_show');
			},
			powerTipRender: function(){
				var $self = $(this);
				$self.powerTip('reposition');
				$(document).trigger('gftt_tooltip_render', [this]);
			},
			powerTipOpen: function(){
				var $self = $(this);
				$self.data('tip-open', true);
				$(document).trigger('gftt_tooltip_open', [this]);
			},
			powerTipClose: function(){
				var $self = $(this);
				$self.data('tip-open', false);
				$('body').removeClass('gftt_show');
				$(document).trigger('gftt_tooltip_close', [this]);
			}
		});

		var $customWrap = $el.closest('.gftt-custom');
		var $gfLabel = $el.closest('.gform-field-label');

		if ( !$customWrap.length ) {
			var $label = $gfLabel.find('.gftt-label');
			gftt._initLabel( $label );
		}

		var $icon = ( $customWrap.length ) ? $customWrap.find('.gftt-icon') : $gfLabel.find('.gftt-icon');
		$icon.on('click', function(e){
			e.preventDefault();
			e.stopImmediatePropagation();
			var $self = $(this);
			if ( $self.data('tip-open') === true ) {
				$self.powerTip('hide');
			}
			else {
				$self.powerTip('show');
			}
		});

	};

	gftt.init = function( form_id, forced ){

		var $formWrapper = $('#gform_wrapper_'+form_id);

		/*
		var gfttHasInit = $formWrapper.data('gftt-init');
		if ( typeof gfttHasInit !== 'undefined' && gfttHasInit.toString() === 'true' && forced !== true ) {
			return;
		}
		*/

		$formWrapper.find('.gfield_label > .gftt-content > div, .gsection_title > .gftt-content > div, .gftt-custom .gftt-content > div').each(function(){
			var $this = $(this);
			gftt._initTooltips( $this );
		});

		$formWrapper.find('[class*="gchoice"] .gftt-content > div').each(function(){
			var $this = $(this);

			var $choice = $this.closest('[class*="gchoice"]');
			$choice.addClass('gform-theme__no-reset--children');

			gftt._initTooltips( $this );
		});


		var dynamicPosition = gform.applyFilters('gftt_dynamic_colorpicker_choices_position', true, form_id);
		if ( dynamicPosition ) {
			$(window).on('resize', function(){
				gftt.colorPickerChoicesDynamicPosition( form_id );
			});
			setTimeout(function(){
				gftt.colorPickerChoicesDynamicPosition( form_id );
			}, 100);
		}

		$formWrapper.data('gftt-init', true);

	}


	gftt.reInitOptions = function(formId, fieldId) {
		var $field = $('#gform_'+formId+' .gfield#field_'+formId+'_'+fieldId);
		var $allOptions = $field.find('[class*="gchoice"]');// TODO: Update to just .gchoice ?
		$allOptions.each(function(i){
			var $option = $(this);
			gftt._initTooltips( $option.find('.gftt-content > div') );
		});
	};

	gftt.option_label_reinit = function(formId, fieldId) {

		var fieldKey = `tt_${formId}_${fieldId}`;
		if ( window.hasOwnProperty(fieldKey) ) {
			clearTimeout(window[fieldKey]);
		}
		window[fieldKey] = setTimeout(function(){
			gftt.reInitOptions( formId, fieldId );
		}, 100);

	}

	window.gform_format_option_label = function(fullLabel, fieldLabel, priceLabel, selectedPrice, price, formId, fieldId, index) {

		gftt.option_label_reinit( formId, fieldId );

		var $field = $('#field_'+formId+'_'+fieldId);

		// Add for cross compat with Image Choices
		if ($field.length && $field.hasClass('image-choices-field') && typeof window.imageChoices_gform_format_option_label === 'function') {
			return window.imageChoices_gform_format_option_label(fullLabel, fieldLabel, priceLabel, selectedPrice, price, formId, fieldId, index);
		}

		// Add for cross compat with Color Picker
		if ( $field.length && $field.hasClass('color-picker-field') && typeof window.colorPicker_gform_format_option_label === 'function') {
			return window.colorPicker_gform_format_option_label(fullLabel, fieldLabel, priceLabel, selectedPrice, price, formId, fieldId, index);
		}

		return fullLabel;
	}


	$(document).on('gform_post_render', function(event, form_id, current_page){
		var globalDelay = ( window.hasOwnProperty('gf_tooltips_init_delay') ) ? parseInt( window.gf_tooltips_init_delay, 10 ) : 0;
		var delay = gform.applyFilters( 'gftt_tooltips_init_delay', globalDelay );
		if ( delay === 0 && $('#gform_' + form_id + ' .gpnf-nested-entries-container').length ) {
			// Seems we need to slightly delay the tooltip init if the form is using Gravity Wiz' Nested Forms and that field has a tooltip
			delay = 1;
		}
		if ( delay > 0 ) {
			setTimeout(function(){
				gftt.init( form_id );
			}, delay * 1000);
		}
		else {
			gftt.init( form_id );
		}
	});

})(jQuery);
